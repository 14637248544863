export const environment = {
  production: true,
  environment_name: 'production',
  release: '2e94b55cc262b87d482395639cade8a65cfe0e0b',

  api_base_url: 'https://api.mankido.de/merchantcenter/v1/',

  sentry_dsn: 'https://fa0dd696f338407d3bc6dd65d9a56285@sentry.mankido.net/4',
  sentry_project_id: '4',

  // configure which new features should be activated
  features: {
    special_points_events: true
  }
};
