<div>
  <h6>{{title}}</h6>
  <div ngbRadioGroup data-toggle="buttons" class="btn-group-toggle">
    <label ngbButtonLabel class="btn btn-outline-primary" [class.active]="rule === 'permanent'" *ngIf="rules.indexOf('permanent') >= 0">
      <input name="rule" ngbButton type="radio" [value]="'permanent'" [(ngModel)]="rule" (change)="ruleHasChanged()">Dauerhaft
    </label>
    <label ngbButtonLabel class="btn btn-outline-primary" [class.active]="rule === 'daily'" *ngIf="rules.indexOf('daily') >= 0">
      <input name="rule" ngbButton type="radio" [value]="'daily'" [(ngModel)]="rule" (change)="ruleHasChanged()">Täglich
    </label>
    <label ngbButtonLabel class="btn btn-outline-primary"[class.active]="rule === 'weekly'" *ngIf="rules.indexOf('weekly') >= 0">
      <input name="rule" ngbButton type="radio" [value]="'weekly'" [(ngModel)]="rule" (change)="ruleHasChanged()">Wöchentlich
    </label>
  </div>


  <div style="width: max-content;" *ngIf="rule === 'singular'">
    <div class="form-inline justify-content-between">
      <span class="mr-1">Startzeitpunkt</span>
      <app-date-time-picker [(datetime)]="datetime.start" [seconds]="false"
                            [minuteStep]="15" (change)="recreateRecurringEvents()"></app-date-time-picker>
    </div>
    <div class="form-inline justify-content-between">
      <span class="mr-1">Endzeitpunkt</span>
      <app-date-time-picker [(datetime)]="datetime.end" [seconds]="false"
                            [minuteStep]="15" (change)="recreateRecurringEvents()"></app-date-time-picker>
    </div>
  </div>


  <div *ngIf="rule === 'weekly'" class="mt-1">
    <fieldset class="form-group">
      <h6>Wähle die Wochentage aus</h6>
      <div class="btn-group btn-group-toggle weekdays" ngbRadioGroup (change)="weekDayChanged()" data-toggle="buttons">
        <label ngbButtonLabel class="btn" [class.btn-primary]="weekDays[1]" [class.btn-outline-primary]="!weekDays[1]">
          <input ngbButton type="checkbox" [(ngModel)]="weekDays[1]">Mo
        </label>
        <label ngbButtonLabel class="btn btn-primary" [class.btn-primary]="weekDays[2]" [class.btn-outline-primary]="!weekDays[2]">
          <input ngbButton type="checkbox" [(ngModel)]="weekDays[2]">Di
        </label>
        <label ngbButtonLabel class="btn btn-primary" [class.btn-primary]="weekDays[3]" [class.btn-outline-primary]="!weekDays[3]">
          <input ngbButton type="checkbox" [(ngModel)]="weekDays[3]">Mi
        </label>
        <label ngbButtonLabel class="btn btn-primary" [class.btn-primary]="weekDays[4]" [class.btn-outline-primary]="!weekDays[4]">
          <input ngbButton type="checkbox" [(ngModel)]="weekDays[4]">Do
        </label>
        <label ngbButtonLabel class="btn btn-primary" [class.btn-primary]="weekDays[5]" [class.btn-outline-primary]="!weekDays[5]">
          <input ngbButton type="checkbox" [(ngModel)]="weekDays[5]">Fr
        </label>
        <label ngbButtonLabel class="btn btn-primary" [class.btn-primary]="weekDays[6]" [class.btn-outline-primary]="!weekDays[6]">
          <input ngbButton type="checkbox" [(ngModel)]="weekDays[6]">Sa
        </label>
        <label ngbButtonLabel class="btn btn-primary" [class.btn-primary]="weekDays[0]" [class.btn-outline-primary]="!weekDays[0]">
          <input ngbButton type="checkbox" [(ngModel)]="weekDays[0]">So
        </label>
      </div>
    </fieldset>
  </div>

  <div *ngIf="rule === 'monthly'" style="display: inline-flex; justify-content: space-between; align-items: center">
    <fieldset class="form-group">
      <h6>Tag</h6>
      <input type="number" [(ngModel)]="day" class="form-control" (change)="recreateRecurringEvents()"
             style="width: 4em;">
    </fieldset>
  </div>

  <div *ngIf="rule === 'yearly'" style="display: inline-flex; justify-content: space-between; align-items: center">
    <fieldset class="form-group">
      <h6>Tag</h6>
      <input type="number" [(ngModel)]="day" class="form-control" (change)="recreateRecurringEvents()" [min]="1"
             [max]="31" style="width: 4em;">
    </fieldset>
    <fieldset class="form-group ml-1">
      <h6>Monat</h6>
      <input type="number" [(ngModel)]="month" class="form-control" (change)="recreateRecurringEvents()" [min]="1"
             [max]="12" style="width: 4em;">
    </fieldset>
  </div>

  <div *ngIf="rule !== 'singular' && rule !== 'permanent'" class="d-flex align-items-center" style="width: 80%">
    <span class="range-label mr-1 mr-md-0">von</span>
    <app-date-time-picker [(datetime)]="datetime.start" [seconds]="false" [minuteStep]="15" [onlyTime]="true"
                          (change)="recreateRecurringEvents()"></app-date-time-picker>
    <span class="range-label mx-1 mx-md-0 ml-md-1">bis</span>
    <app-date-time-picker [(datetime)]="datetime.end" [seconds]="false" [minuteStep]="15" [onlyTime]="true"
                          (change)="recreateRecurringEvents()"></app-date-time-picker>
  </div>
</div>
